import React from "react";
import { Header as RSuiteHeader } from "rsuite";

const headerStyles = {
  textAlign: "center" as "center",
  background: "#8ca8bc",
};

interface Props {}

const Header: React.FC<Props> = () => {
  return (
    <>
      <RSuiteHeader style={headerStyles}> </RSuiteHeader>
    </>
  );
};

export default Header;
