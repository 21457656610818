import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import React, { useEffect } from "react";
import { FlexboxGrid } from "rsuite";

interface Props {}

const Finished: React.FC<Props> = () => {
  useEffect(() => {
    document.title =
      "資料請求フォーム – Shingari Cloud：最もシンプルに、最も安価に、でも簡単に使うことができるE2Eテストサービス";
  }, []);
  return (
    <>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item
          colspan={24}
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "500px",
            }}
          >
            <FlexboxGrid
              justify="center"
              style={{ color: "#6BA0FC", paddingTop: "53px" }}
            >
              <FontAwesomeIcon icon={faCheckCircle} size="6x" />
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <p className="thank-you-title">Thank you!</p>
            </FlexboxGrid>
            <FlexboxGrid justify="center">
              <p className="thank-you-message">
                {i18n.t("doc-req-finished.thanks") as string}
                <br />
                {i18n.t("doc-req-finished.detail") as string}
              </p>
            </FlexboxGrid>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default Finished;
