import React from "react";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="to_contact">
      <p className="contact_text">
        <i className="fas fa-check"></i> しんがりクラウド
      </p>
    </div>
  );
};

export default Footer;
