import i18n from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, FlexboxGrid, Form, Header } from "rsuite";
import Button from "../../components/atoms/Button";
import FormLabel from "../../components/atoms/FormLabel";
import Input from "../../components/atoms/Input";
import Steps from "../../components/molecules/Steps";
import { DeployPage } from "../../config/BaseConfig";
import ConsoleAPIClient from "../../service/utils/ConsoleAPIClient";

const validateEmail = (email: string) => {
  const ok_pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return ok_pattern.test(email);
};

const validateTenantCode = (tenant_code: string) => {
  const ok_pattern = new RegExp(/^[a-zA-Z0-9]+$/);
  return ok_pattern.test(tenant_code);
};

interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState({
    company_name: "",
    name: "",
    email: "",
    tenant_code: "",
    full_user: 1,
  });

  useEffect(() => {
    (async () => {
      await ConsoleAPIClient.getWithoutAuth("/api/health");
    })();
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (!validateEmail(values.email)) {
      setIsSubmitting(false);
      return;
    }
    if (
      DeployPage === "TRIAL_FORM" &&
      !validateTenantCode(values.tenant_code)
    ) {
      setIsSubmitting(false);
      return;
    }
    if (values.name === "" || values.email === "") {
      setIsSubmitting(false);
      return;
    }
    try {
      const urlEndpoint =
        DeployPage === "APPLICATION_FORM"
          ? "/api/v1/customers"
          : DeployPage === "TRIAL_FORM"
          ? "/api/v1/subscriptions/trial"
          : "/api/v1/document-request";
      const params =
        DeployPage === "TRIAL_FORM"
          ? {
              subscription: {
                company_name: values.company_name || "個人",
                name: values.name,
                email: values.email,
                tenant_code: values.tenant_code,
                full_user: values.full_user,
              },
            }
          : {
              customer: {
                company_name: values.company_name || "個人",
                name: values.name,
                email: values.email,
              },
            };
      await ConsoleAPIClient.postWithoutAuth(urlEndpoint, params);
      const navigateEndpoint =
        DeployPage === "APPLICATION_FORM"
          ? "/registered"
          : DeployPage === "TRIAL_FORM"
          ? "/finished"
          : "/document-requested";
      navigate(navigateEndpoint);
    } catch {
      setIsSubmitting(false);
    }
  }, [navigate, values]);

  function handleInputChange(
    value: string | number | readonly string[],
    event: React.SyntheticEvent<Element, Event>
  ) {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    const str_value = value.toString();
    setValues({ ...values, [name]: str_value });
  }

  return (
    <FlexboxGrid justify="center">
      {DeployPage === "APPLICATION_FORM" ? (
        <FlexboxGrid.Item
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          colspan={24}
        >
          <Steps activeStep={1} />
        </FlexboxGrid.Item>
      ) : null}
      <FlexboxGrid.Item
        style={{
          paddingTop: "25px",
          paddingInline: "50px",
          display: "flex",
          justifyContent: "center",
          maxWidth: "100%",
        }}
        colspan={24}
      >
        <Header
          as="h3"
          style={{
            fontSize: "24px",
            color: "#083464",
            maxWidth: "100%",
          }}
        >
          {i18n.t("register_form.title") as string}
        </Header>
        <Divider
          style={{
            marginTop: "2px",
            marginBottom: "12px",
          }}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        style={{
          padding: "25px",
          display: "flex",
          justifyContent: "center",
        }}
        colspan={24}
      >
        <Form
          style={{
            width: "500px",
          }}
        >
          <Form.Group controlId="company_name">
            <FormLabel text={i18n.t("register_form.company_name") as string} />
            <Input
              name="company_name"
              value={values.company_name}
              onChange={handleInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={
                i18n.t("register_form.samples.company_name") as string
              }
            />
          </Form.Group>
          <Form.Group controlId="name">
            <FormLabel
              text={i18n.t("register_form.username") as string}
              required
            />
            <Input
              name="name"
              value={values.name}
              onChange={handleInputChange}
              type="text"
              disabled={false}
              step=""
              placeHolder={i18n.t("register_form.samples.username") as string}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <FormLabel
              text={i18n.t("register_form.email") as string}
              required
            />
            <Input
              name="email"
              value={values.email}
              onChange={handleInputChange}
              type="email"
              disabled={false}
              step=""
              placeHolder={i18n.t("register_form.samples.email") as string}
            />
          </Form.Group>

          {DeployPage === "TRIAL_FORM" ? (
            <Form.Group controlId="tenant_code">
              <FormLabel
                required
                text={i18n.t("functions.tenant_code") as string}
              />
              <Input
                name="tenant_code"
                value={values.tenant_code}
                onChange={handleInputChange}
                type="tenant_code"
                disabled={false}
                step=""
                placeHolder={i18n.t("functions.samples.tenant_code") as string}
              />
              <Form.HelpText>
                {i18n.t("functions.tenant_code_description1") as string}
                <br></br>
                {i18n.t("functions.tenant_code_description2") as string}
              </Form.HelpText>
            </Form.Group>
          ) : null}

          <Form.Group
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              text={
                DeployPage === "TRIAL_FORM"
                  ? (i18n.t("register_form.submit_trial") as string)
                  : (i18n.t("register_form.submit") as string)
              }
              onClick={handleSubmit}
              isLoading={isSubmitting}
            />
          </Form.Group>

          <Form.Group
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <a
              href="https://www.shingari.cloud/terms-of-use"
              target="_blank"
              rel="noreferrer noopener"
            >
              {i18n.t("common.terms_of_service") as string}
            </a>
            <p style={{ paddingInline: "5px" }}>/</p>
            <a
              href="https://www.shingari.cloud/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              {i18n.t("common.privacy_policy") as string}
            </a>
          </Form.Group>
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Register;
