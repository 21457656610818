import { loadStripe } from "@stripe/stripe-js";

const env = process.env.REACT_APP_BUILD_ENV;
let prefix = "REACT_APP_LOCAL_";
if (process.env.REACT_APP_RUN_ENV !== "local") {
  switch (env) {
    case "production":
      prefix = "REACT_APP_PRODUCTION_";
      break;
    case "staging":
      prefix = "REACT_APP_STAGING_";
      break;
    case "development":
      prefix = "REACT_APP_DEVELOPMENT_";
      break;
  }
}

export const ConsoleAPIBaseURL = process.env[
  prefix + "CONSOLE_BASE_API_URL"
] as string;
export const ConsoleAPIKey = process.env[prefix + "CONSOLE_API_KEY"] as string;
export const StripePublicKey = process.env[
  prefix + "STRIPE_PUBLIC_KEY"
] as string;
export const stripePromise = loadStripe(StripePublicKey);

export const DeployPage = process.env[prefix + "DEPLOY_PAGE"] as string;
