import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import i18n from "i18next";
import React from "react";
import { Navigate } from "react-router-dom";
import { Col, Divider, FlexboxGrid, Form, Grid, Header, Row } from "rsuite";
import Button from "../../components/atoms/Button/Button";
import FormLabel from "../../components/atoms/FormLabel";
import Input from "../../components/atoms/Input";
import Steps from "../../components/molecules/Steps";
import StripeService from "../../service/stripe/StripeService";
import ConsoleAPIClient from "../../service/utils/ConsoleAPIClient";

const validateTenantCode = (tenant_code: string) => {
  const ok_pattern = new RegExp("[0-9a-zA-Z\\-\\_]+");
  return ok_pattern.test(tenant_code) && tenant_code.length >= 5;
};

interface Props {
  stripe: any;
  elements: any;
}

interface State {
  token: string;
  customer_id: string;
  email: string;
  tenant_code: string;
  full_user: number;
  finished: boolean;
  isSubmitting: boolean;
}

class CardSetupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      token: "",
      customer_id: "",
      email: "",
      tenant_code: "",
      full_user: 1,
      finished: false,
      isSubmitting: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMinus = this.handleMinus.bind(this);
    this.handlePlus = this.handlePlus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const customer_id = localStorage.getItem("customer_id");
    const email = localStorage.getItem("email");
    if (customer_id !== null && email !== null) {
      this.setState({
        customer_id,
        email,
      });
    } else {
      document.location.href = "/";
    }
  }

  handleInputChange(
    value: string | number | readonly string[] | boolean,
    event: React.SyntheticEvent<Element, Event>
  ) {
    const name: string | null = event.currentTarget.getAttribute("name");
    if (name == null) {
      return;
    }
    const newState = { [name]: value } as Pick<State, keyof State>;
    this.setState(newState);
  }

  handleMinus() {
    this.setState({
      full_user: this.state.full_user - 1,
    });
  }

  handlePlus() {
    this.setState({
      full_user: this.state.full_user + 1,
    });
  }

  handleSubmit = async () => {
    this.setState({
      isSubmitting: true,
    });
    if (!validateTenantCode(this.state.tenant_code)) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }
    try {
      const response = await StripeService.confirmCardSetup(
        stripe,
        elements,
        this.state.customer_id
      );
      const a = await ConsoleAPIClient.postWithoutAuth(
        "/api/v1/subscriptions",
        {
          subscription: {
            email: this.state.email,
            customer_id: this.state.customer_id,
            default_payment_method_id: response.setupIntent.payment_method,
            tenant_code: this.state.tenant_code,
            full_user: this.state.full_user,
          },
        }
      );
      this.setState({
        finished: true,
      });
    } catch {
      this.setState({
        isSubmitting: false,
      });
      return;
    }
  };

  render() {
    const mystyle = {
      style: {
        base: {
          color: "#00548E",
          backgroundColor: "#F1F3F6",
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "#00548E99",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
    return (
      <>
        <FlexboxGrid justify="center">
          {this.state.finished && <Navigate to="/finished" replace={true} />}
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            colspan={24}
          >
            <Steps activeStep={2} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            style={{
              paddingTop: "25px",
              paddingInline: "50px",
              display: "flex",
              justifyContent: "center",
              maxWidth: "100%",
            }}
            colspan={24}
          >
            <Header
              as="h3"
              style={{
                fontSize: "24px",
                color: "#083464",
                maxWidth: "100%",
              }}
            >
              {i18n.t("card_setup.title") as string}
            </Header>
            <Divider
              style={{
                marginTop: "2px",
                marginBottom: "12px",
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            colspan={24}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FlexboxGrid.Item
              colspan={16}
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Form
                style={{
                  maxWidth: "500px",
                }}
              >
                <Form.Group controlId="tenant_code">
                  <FormLabel text={i18n.t("functions.tenant_code") as string} />
                  <Input
                    name="tenant_code"
                    value={this.state.tenant_code}
                    onChange={this.handleInputChange}
                    type="tenant_code"
                    disabled={false}
                    step=""
                    placeHolder={
                      i18n.t("functions.samples.tenant_code") as string
                    }
                  />
                  <Form.HelpText>
                    {i18n.t("functions.tenant_code_description1") as string}
                    <br></br>
                    {i18n.t("functions.tenant_code_description2") as string}
                  </Form.HelpText>
                </Form.Group>
                <Form.Group>
                  <Grid fluid style={{ paddingTop: "10px" }}>
                    <Row>
                      <Col xs={24}>
                        <FormLabel
                          text={i18n.t("card_setup.card_number") as string}
                        />
                        <CardNumberElement options={mystyle} />
                      </Col>
                    </Row>
                  </Grid>
                </Form.Group>
                <Form.Group>
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        <FormLabel
                          text={i18n.t("card_setup.card_month_year") as string}
                        />
                        <CardExpiryElement options={mystyle} />
                      </Col>
                      <Col xs={12}>
                        <FormLabel
                          text={i18n.t("card_setup.card_cvc") as string}
                        />
                        <CardCvcElement options={mystyle} />
                        <p style={{ paddingTop: "5px" }}>
                          {i18n.t("card_setup.cvc_help") as string}
                        </p>
                      </Col>
                    </Row>
                  </Grid>
                </Form.Group>
                <Form.Group>
                  <Grid fluid>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Button
                          text={i18n.t("card_setup.submit") as string}
                          isLoading={this.state.isSubmitting}
                          onClick={this.handleSubmit}
                        ></Button>
                      </Col>
                    </Row>
                  </Grid>
                </Form.Group>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </>
    );
  }
}

const CardSetup = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CardSetupForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

export default CardSetup;
