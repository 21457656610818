import { Form } from "rsuite";
import RequiredLabel from "../RequiredLabel/RequiredLabel";
import classes from "./FormLabel.module.scss";
interface Props {
  text: string;
  required?: boolean;
}

const FormLabel = ({ text, required }: Props) => {
  return (
    <Form.ControlLabel className={classes.main_css_jp}>
      {text} {required && <RequiredLabel />}
    </Form.ControlLabel>
  );
};

export default FormLabel;
