import React from "react";

interface Props {
  title: string;
  text: string;
  isActive: boolean;
  width?: number;
}

const Step: React.FC<Props> = ({ title, text, isActive, width }) => {
  return (
    <li className={`step ${isActive ? "active" : ""}`} style={{ width }}>
      {title}
      <br />
      {text}
    </li>
  );
};
export default Step;
