import i18n from "i18next";
import React from "react";
import { FlexboxGrid } from "rsuite";
import Steps from "../../components/molecules/Steps";

interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item
        style={{
          paddingTop: "25px",
          display: "flex",
          justifyContent: "center",
        }}
        colspan={24}
      >
        <Steps activeStep={1} />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        colspan={24}
        style={{
          padding: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: "100%",
            fontWeight: "700",
            color: "#083464",
            maxWidth: "650px",
          }}
        >
          {i18n.t("register_form.completed") as string}
          <br />
          {i18n.t("register_form.completion_detail") as string}
        </p>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Register;
