import i18n from "i18next";
import React from "react";
import { FlexboxGrid } from "rsuite";
import Steps from "../../components/molecules/Steps";
import { DeployPage } from "../../config/BaseConfig";

interface FinishedProps {}

const Finished: React.FC<FinishedProps> = () => {
  return (
    <FlexboxGrid justify="center">
      {DeployPage === "APPLICATION_FORM" ? (
        <FlexboxGrid.Item
          style={{
            paddingTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          colspan={24}
        >
          <Steps activeStep={3} />
        </FlexboxGrid.Item>
      ) : null}
      <FlexboxGrid.Item
        colspan={24}
        style={{
          padding: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontSize: "100%",
            fontWeight: "700",
            color: "#083464",
            maxWidth: "650px",
          }}
        >
          {i18n.t("finished.thanks") as string}
          <br />
          {i18n.t("finished.detail") as string}
        </p>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Finished;
