import React from "react";
import Step from "../../atoms/Step";

import { GetWindowSize } from "../../../hooks/GetWindowSize";

interface Props {
  activeStep: number;
}

const Steps: React.FC<Props> = ({ activeStep }) => {
  const { width } = GetWindowSize();

  var title = "";
  var text = "";
  if (activeStep === 1) {
    title = "Step 1 / 3";
    text = "お申し込みフォーム入力";
  } else if (activeStep === 2) {
    title = "Step 2 / 3";
    text = "お支払い方法ご登録";
  } else if (activeStep === 3) {
    title = "Step 3 / 3";
    text = "お申し込み完了";
  }

  return (
    <div
      className="content"
      style={{
        width: width - 50,
      }}
    >
      {width < 768 ? (
        <ul
          className="steps"
          style={{
            paddingInlineStart: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Step title={title} text={text} isActive={true} width={width - 100} />
        </ul>
      ) : (
        <ul
          className="steps"
          style={{
            paddingInlineStart: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Step
            title="Step 1"
            text="お申し込みフォーム入力"
            isActive={activeStep === 1 ? true : false}
          />
          <Step
            title="Step 2"
            text="お支払い方法ご登録"
            isActive={activeStep === 2 ? true : false}
          />
          <Step
            title="Step 3"
            text="完了"
            isActive={activeStep === 3 ? true : false}
          />
        </ul>
      )}
    </div>
  );
};
export default Steps;
