import { Elements } from "@stripe/react-stripe-js";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";
import CardSetup from "./pages/CardSetup";
import DocumentRequested from "./pages/DocumentRequested";
import Finished from "./pages/Finished";
import Register from "./pages/Register";
import Registered from "./pages/Registered";

import { stripePromise } from "./config/BaseConfig";
import Error404 from "./pages/Error404";
import Login from "./pages/Login";

import { DeployPage } from "./config/BaseConfig";

const App = () => {
  return (
    <div id="main_content" style={{ position: "absolute", width: "100%" }}>
      <Router basename="/">
        <Elements stripe={stripePromise}>
          <Header />
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/card-setup" element={<CardSetup />}></Route>
            <Route path="/error" element={<Error404 />}></Route>
            <Route path="/registered" element={<Registered />}></Route>
            <Route path="/finished" element={<Finished />}></Route>
            <Route
              path="/document-requested"
              element={<DocumentRequested />}
            ></Route>
            <Route path="*" element={<Register />}></Route>
          </Routes>
          {DeployPage === "TRIAL_FORM" ? null : <Footer />}
        </Elements>
      </Router>
    </div>
  );
};

export default App;
