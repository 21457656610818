import i18n from "i18next";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import ConsoleAPIClient from "../../service/utils/ConsoleAPIClient";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      const params = new URLSearchParams(document.location.search);
      const token = params.get("token") || "";
      if (token === "") {
        navigate("/");
      } else {
        const response = await ConsoleAPIClient.postWithoutAuth(
          "/api/v1/login",
          {
            login: {
              token: token,
            },
          }
        );
        localStorage.setItem(
          "customer_id",
          response.data.login.stripe_customer_id
        );
        localStorage.setItem("email", response.data.login.email);
        navigate("/card-setup");
      }
    };
    f();
  }, [navigate]);

  return (
    <div>
      <Loader content="Loading..." />
      <hr />
      <Loader content={i18n.t("loading") as string} vertical />
    </div>
  );
};

export default Login;
